:root {
  --color-naranja: #ff4949;
  --color-gris-1: #f4f4f6;
  --color-gris-2: #eaeaea;
  --color-gris-3: #a0a0a0;
  --color-gris-4: #505050;
}

.texto-naranja {
  color: var(--color-naranja);
}

.texto-gris {
  color: var(--color-gris-4);
}

.bg-naranja {
  background-color: var(--color-naranja);
}

.bg-gris-1 {
  background-color: var(--color-gris-1);
}

.bg-gris-2 {
  background-color: var(--color-gris-2);
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
  background-color: transparent !important;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background: #424242;
}
